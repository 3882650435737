<template>
  <section>
      <div class="content-header">
        <h2>26 weekly activities to help students through the school year</h2>
      </div>
      <div class="content-wrapper">
        <p>This <a href="https://www.ryerson.ca/content/dam/thriveru/resources/ThriveRU-WorkBook-Fillable.pdf" target="_blank">workbook and calendar of weekly exercises</a> is designed to help you manage challenges and cultivate your well-being. </p>
        <p>For example, in week 7, you perform random acts of kindness. Over 7 days, you remark on how your random acts of kindness improve your life or mood.</p>
        <p>Each activity is proven to be effective in increasing life satisfaction, happiness and resilience. </p>
        <p>There is both a student workbook and a facilitator’s manual to help you deepen and enrich the experience for students.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
